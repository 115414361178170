import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AppTable,
  DetailItem,
  getStandardError,
  HeaderSection,
  IAppTableColumn,
  useFetchList,
} from "@qlibs/react-components";
import { httpRequest } from "../../../helpers/api";
import useDetailBreadcrumbs from "../../../hooks/useDetailBreadcrumbs";
import { Button, Card, Col, Empty, List, Modal, Row, Space, Spin } from "antd";
import {
  AnswerProperties,
  QuestionProperties,
  QuizProperties,
} from "../../../types/quiz.type";
import { EventsProps } from "../../../types/event.type";
import { CourseProps } from "../../../types/course.type";

type ByQuestion = {
  questionId: string;
  metaQuestions: any;
  question: QuestionProperties;

  answers: {
    userId: string;
    answerText: string;
    choosenAnswerIds: string[];
    metaQuestionAnswers: AnswerProperties[];

    answerAsNumber?: number;
  }[];

  averageAnswerAsNumber?: number;
};

interface ILocation {
  quizId: string;
  eventId: string;
}

type Props = {
  quizId?: string;
  eventId?: string;
  hideHeader?: boolean;
  detailButtonMode?: "modal";
};
export default function DetailResultByEvent({
  quizId: quizIdFromProps,
  eventId: eventIdFromProps,
  ...props
}: Props) {
  const { quizId: quizIdFromParams, eventId: eventIdFromParams } = useParams<
    keyof ILocation
  >() as ILocation;

  const quizId = quizIdFromProps || quizIdFromParams;
  const eventId = eventIdFromProps || eventIdFromParams;

  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const { isLoading, data } = useFetchList<ByQuestion>({
    httpRequest: httpRequest as any,
    endpoint: `quiz-attempt-by-event/${quizId}/${eventId}`,
  });

  const [isLoadingQuiz, setIsLoadingQuiz] = useState(true);
  const [isLoadingEvent, setIsLoadingEvent] = useState(true);
  const [isLoadingCourse, setIsLoadingCourse] = useState(true);
  const [isLoadingAverage, setIsLoadingAverage] = useState(true);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const [quiz, setQuiz] = useState<QuizProperties>();
  const [event, setEvent] = useState<EventsProps>();
  const [course, setCourse] = useState<CourseProps>();
  const [average, setAverage] = useState<any>(0);

  useEffect(() => {
    fetchQuiz();
    fetchEvent();
    fetchAverage();
  }, []);

  useEffect(() => {
    const bcDetails = [];
    if (event?.eventId) {
      bcDetails.push({
        field: "eventId",
        value: event?.eventId,
        label: event?.title,
      });
    }
    if (quiz?.quizId) {
      bcDetails.push({
        field: "quizId",
        value: quiz?.quizId,
        label: quiz?.title,
      });
    }
    setBreadcrumbDetails(bcDetails);
  }, [quiz, event]);

  const fetchQuiz = async () => {
    httpRequest
      .get("/quizzes/" + quizId)
      .then((res) => {
        if (res.data.payload) {
          setQuiz(res.data.payload);

          const bcDetails = [
            {
              field: "quizId",
              value: res.data.payload.quizId,
              label: res.data.payload.title,
            },
          ];
          setBreadcrumbDetails(bcDetails);
        }
        setIsLoadingQuiz(false);
      })
      .catch((err) => {
        setIsLoadingQuiz(false);
        getStandardError(err, { showToast: true });
      });
  };

  const fetchAverage = async () => {
    httpRequest
      .get(`quiz-attempt-by-event/${quizId}/${eventId}/average`)
      .then((res) => {
        if (res.data.payload) {
          setAverage(res.data.payload.average ?? 0);
        }
        setIsLoadingAverage(false);
      })
      .catch((err) => {
        setIsLoadingAverage(false);
        getStandardError(err, { showToast: true });
      });
  };

  const exportExcel = async () => {
    setIsLoadingAction(true);
    try {
      const response: any = await httpRequest.get(
        `quiz-attempt-by-event/${quizId}/${eventId}/export-excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      const getFileNameFromContentDisposition = (contentDisposition: any) => {
        let filename = contentDisposition.split("filename=")[1];
        return filename;
      };

      const fileNameWithExtension = getFileNameFromContentDisposition(
        response.headers["content-disposition"]
      );
      link.href = url;
      link.setAttribute("download", fileNameWithExtension);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setIsLoadingAction(false);
    } catch (error) {
      setIsLoadingAction(false);
      getStandardError(error, { showToast: true });
    }
  };

  const fetchEvent = async () => {
    httpRequest
      .get("/event/" + eventId)
      .then((res) => {
        if (res.data.payload) {
          setEvent(res.data.payload);
          fetchCourse(res.data.payload.externalId);
        }
        setIsLoadingEvent(false);
      })
      .catch((err) => {
        setIsLoadingEvent(false);
        getStandardError(err, { showToast: true });
      });
  };

  const fetchCourse = async (courseId: string) => {
    httpRequest
      .get("/course/" + courseId)
      .then((res) => {
        if (res.data.payload) {
          setCourse(res.data.payload);
        }
        setIsLoadingCourse(false);
      })
      .catch((err) => {
        setIsLoadingCourse(false);
        getStandardError(err, { showToast: true });
      });
  };

  const columns: IAppTableColumn<ByQuestion>[] = [
    {
      title: "Question Id",
      dataIndex: "questionId",
      key: "questionId",
      width: 250,
    },
    {
      title: "Question Text",
      dataIndex: ["question", "questionText"],
      key: "questionText",
      width: 400,
      type: "html-modal",
    },
    {
      title: "Average",
      dataIndex: "averageAnswerAsNumber",
      key: "averageAnswerAsNumber",
      render: (averageAnswerAsNumber, record) =>
        averageAnswerAsNumber ? Number(averageAnswerAsNumber).toFixed(2) : "",
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      render: (_: any, record) =>
        !record.averageAnswerAsNumber &&
        record.answers.find((ans) => ans.answerText) ? (
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                title: "Detail",
                content: (
                  <ul>
                    {record.answers.map((ans) => (
                      <li>{ans.answerText}</li>
                    ))}
                  </ul>
                ),
                closable: true,
                footer: false,
              });
            }}
          >
            Detail
          </Button>
        ) : (
          false
        ),
    },
  ];

  return (
    <React.Fragment>
      {!props.hideHeader && (
        <HeaderSection
          icon="back"
          title="SUBMISSIONS By Event"
          rightAction={
            <Space>
              <Button
                style={{ padding: "0px 32px" }}
                loading={isLoadingAction}
                type="primary"
                onClick={() => exportExcel()}
              >
                Export Excel
              </Button>
            </Space>
          }
        />
      )}

      <Card style={{ marginBottom: 10 }}>
        <Row>
          <Col span={24}>
            {isLoadingQuiz ? (
              <div style={{ marginBottom: 40 }}>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Form" value={quiz?.title} />
            )}
          </Col>
          <Col span={12}>
            {isLoadingEvent ? (
              <div>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Event" value={event?.title} />
            )}
          </Col>
          <Col span={12}>
            {isLoadingCourse ? (
              <div>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Trainer" value={course?.trainer} />
            )}
          </Col>
          <Col span={12}>
            {isLoadingAverage ? (
              <div>
                <Spin />
              </div>
            ) : (
              <DetailItem label="Average" value={average} />
            )}
          </Col>
        </Row>
      </Card>

      <Spin spinning={isLoading || isLoading}>
        {!isLoading && !eventId ? (
          <Empty description="Submissions is still empty for now" />
        ) : (
          <Card bordered={false}>
            {!props.hideHeader ? (
              <AppTable
                isLoading={isLoading}
                keyId="id"
                columns={columns.filter((v) => v.key)}
                data={data.sort((a, b) => a.question.order - b.question.order)}
                pagination={false}
                // onChangeLimit={changeLimit}
                // onChangePage={changePage}
              />
            ) : props.hideHeader ? (
              <AppTable
                isLoading={isLoading}
                keyId="id"
                columns={columns.filter((v) => v.key)}
                data={data.sort((a, b) => a.question.order - b.question.order)}
                pagination={false}
                // onChangeLimit={changeLimit}
                // onChangePage={changePage}
              />
            ) : (
              false
            )}
          </Card>
        )}
      </Spin>
    </React.Fragment>
  );
}
