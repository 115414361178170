import {
  HomeOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  UsergroupDeleteOutlined,
  SafetyCertificateOutlined,
  FormOutlined,
  CalendarOutlined,
  FolderOpenOutlined,
  PictureOutlined,
  RocketOutlined,
  FireOutlined,
  StarOutlined,
} from "@ant-design/icons";

type IMenu = {
  type?: 'group' | 'submenu';
  key?: string;
  label: string;
  icon?: any;
  permission?: boolean | string[];
  permissionOp?: 'OR' | 'AND';
  description?: string;

  children?: IMenu[];
};
export const MENUS: IMenu[] = [
  {
    key: '/dashboard',
    label: 'Dashboard',
    icon: <HomeOutlined />,
    permission: true,
    description: 'See insight here',
  },
  {
    key: '/schedule',
    label: 'Schedule',
    icon: <CalendarOutlined />,
    permission: ['EVENT.SIDEBAR_MENU'],
    description: 'Manage schedule here',
  },
  {
    key: '/program',
    label: 'Program (Batch)',
    icon: <StarOutlined />,
    permission: ['PROGRAM.SIDEBAR_MENU'],
    description: 'Manage program here',
  },
  {
    type: 'group',
    label: 'ATTENDANCE',
    children: [
      {
        permission: ['QRCODE.SIDEBAR_MENU'],
        key: '/daily-attendance',
        label: 'Daily Attendance',
        icon: <FileTextOutlined />,
        description: 'Manage all daily attendances here',
      },
      {
        permission: ['QRCODE.SIDEBAR_MENU'],
        key: '/daily-attendance-by-talent',
        label: 'Attendance By Talent',
        icon: <FileTextOutlined />,
        description: 'Manage all daily attendances here',
      },
      {
        permission: ['QRCODE.SIDEBAR_MENU'],
        key: '/daily-achievement-by-talent',
        label: 'Daily Achievement By Talent',
        icon: <FileTextOutlined />,
        description: 'Manage all daily achievement here',
      },

      // {
      //   permission: ['QRCODE.LIST'],
      //   key: '/attendance-by-talent',
      //   label: 'Attendance by Talent',
      //   icon: <FileTextOutlined />,
      //   description: 'Manage all attendances here',
      // },
      {
        permission: ['QRCODE.SIDEBAR_MENU'],
        key: '/daily-qrcode',
        label: 'Daily QR Code',
        icon: <FileTextOutlined />,
        description: 'Manage your daily qrcode here',
      },
    ],
  },
  {
    type: 'group',
    label: 'TRAINING & QUIZ',
    children: [
      {
        permission: ['TRAINING.SIDEBAR_MENU'],
        key: '/training',
        label: 'Training Content',
        icon: <FileTextOutlined />,
        description: 'Manage your training here',
      },
      {
        permission: ['QUIZ.SIDEBAR_MENU'],
        type: 'submenu',
        label: 'Quiz & Survey',
        icon: <FormOutlined />,
        children: [
          {
            permission: ['QUIZ.SIDEBAR_MENU'],
            key: '/pretest--posttest',
            label: 'Pre Test & Post Test',
            icon: <FormOutlined />,
            description: 'Manage your pre test & post test',
          },
          {
            permission: ['QUIZ.SIDEBAR_MENU'],
            key: '/micro-learning',
            label: 'Micro Learning',
            icon: <FormOutlined />,
            description: 'Manage your micro learning here',
          },
          {
            permission: ['QUIZ.SIDEBAR_MENU'],
            key: '/survey',
            label: 'Survey',
            icon: <FormOutlined />,
            description: 'Manage your survey, feedback form, etc',
          },
          {
            permission: ['QUIZ.SIDEBAR_MENU'],
            key: '/archive-quiz',
            label: 'Archive Quiz',
            icon: <FolderOpenOutlined />,
          },
          {
            permission: ['QUIZ.SIDEBAR_MENU', 'QUIZ.CHANGELOG_LIST'],
            key: '/quiz-change-logs',
            label: 'Change Logs',
            icon: <FireOutlined />,
            description: 'See change logs here',
          },
        ],
      },
    ],
  },
  {
    type: 'group',
    label: 'EVALUATION',
    children: [
      {
        permission: ['EVALUATION.SIDEBAR_MENU'],
        key: '/evaluation',
        label: 'Evaluation',
        icon: <RocketOutlined />,
        description: 'See insight here',
      },
      {
        permission: ['EVALUATION.SIDEBAR_MENU', 'CHANGELOG.LIST'],
        permissionOp: 'AND',
        key: '/evaluation-change-logs',
        label: 'Change Logs',
        icon: <FireOutlined />,
        description: 'See change logs here',
      },
    ],
  },
  // {
  //   type: 'group',
  //   label: 'REPORT',
  //   children: [
  //     {
  //       // permission: ['REPORT.LIST'],
  //       permission: true,
  //       key: '/progress-report',
  //       label: 'Progress Report',
  //       icon: <FileTextOutlined />,
  //     },
  //     {
  //       // permission: ['REPORT.LIST'],
  //       permission: true,
  //       key: '/final-report',
  //       label: 'Final Report', // individual report, scoring & best talent
  //       icon: <FileTextOutlined />,
  //     },
  //   ],
  // },
  {
    type: 'group',
    label: 'CONFIGURATION',
    children: [
      {
        permission: ['APP_CONFIG.SIDEBAR_MENU'],
        key: '/basic-configurations',
        label: 'Basic Config',
        icon: <InfoCircleOutlined />,
      },
      {
        permission: ['APP_CONFIG.SIDEBAR_MENU'],
        key: '/preference',
        label: 'Content',
        icon: <InfoCircleOutlined />,
      },
      {
        permission: ['BANNER.SIDEBAR_MENU'],
        key: '/banner',
        label: 'Banner',
        icon: <PictureOutlined />,
      },
      // {
      //   permission: true,
      //   key: '/tnc-and-privacy-policy',
      //   label: 'TNC and Privacy',
      //   icon: <InfoCircleOutlined />,
      // },
      // {
      //   permission: ["FAQ.LIST"],
      //   key: "/faqs",
      //   label: "FAQ",
      //   icon: <InfoCircleOutlined />,
      // },
      // {
      //   permission: ["REGION.LIST"],
      //   key: "/regions",
      //   label: "Region",
      //   icon: <EnvironmentOutlined />,
      // },
    ],
  },
  {
    type: 'group',
    label: 'USERS',
    children: [
      {
        permission: ['USER.SIDEBAR_MENU'],
        key: '/talent',
        label: 'Talent',
        icon: <UsergroupDeleteOutlined />,
      },
      {
        permission: ['USER.SIDEBAR_MENU'],
        key: '/mentor',
        label: 'Mentor',
        icon: <UsergroupDeleteOutlined />,
      },
      {
        permission: ['USER.SIDEBAR_MENU'],
        key: '/monev',
        label: 'Monev User',
        icon: <UsergroupDeleteOutlined />,
      },
      {
        permission: ['USER.SIDEBAR_MENU'],
        key: '/coordinator',
        label: 'Coordinator',
        icon: <UsergroupDeleteOutlined />,
      },
      {
        permission: ['USER.SIDEBAR_MENU'],
        key: '/admin',
        label: 'Admin',
        icon: <UsergroupDeleteOutlined />,
      },
      {
        permission: ['USER.SIDEBAR_MENU'],
        key: '/customer',
        label: 'Customer (Public)',
        icon: <UsergroupDeleteOutlined />,
      },
      {
        permission: ['ROLE.SIDEBAR_MENU'],
        key: '/role',
        label: 'Role & Permission',
        icon: <SafetyCertificateOutlined />,
      },
    ],
  },
];

export const getFlatMenus = (menus: IMenu[]) => {
  return menus
    .reduce((acc, curr) => {
      if (curr.children) {
        curr.children.forEach((child: IMenu) => {
          acc.push(child);

          if (child.children) {
            child.children.forEach((child2: IMenu) => {
              acc.push(child2);
            });
          }
        });
      }
      acc.push(curr);
      return acc;
    }, [] as any[])
    .filter((item: any) => item.key)
    .filter((item: any) => item.type !== "group");
};

export const MENUS_FLAT = getFlatMenus(MENUS);
console.info("menus_flat", MENUS_FLAT);

export const getMenu = () => {
  return (MENUS_FLAT.find(
    (item: any) => item.key === window?.location?.pathname
  ) || {}) as { label: string; description: string };
};
