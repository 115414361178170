import React, { useEffect, useRef, useState } from "react";
import { TagOutlined } from "@ant-design/icons";
import {
  BaseResponsePaginationProps,
  getStandardError,
  HeaderSection,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import { AttendanceProps, AttendanceStatus } from "../../types/attendance.type";
import { UserProperties } from '../../services/openapi';
import AbsensiTable, { generateDates, IAbsensiData, IEventData } from './AbsensiTable';
import moment from 'moment';
import useProgramStore from '../../zustand/useProgramStore';
import { SINGLE_BATCH_END_AT, SINGLE_BATCH_START_AT } from '../../const/singleBatch';
import { EEventType, EventsProps } from '../../types/event.type';
import { Button, Col, Row } from 'antd';
import FilterDate from '../../components/Form/FilterDate';
import { isPast, isToday } from '../../helpers/datetime';

type Props = { talentId?: string, hideHeader?: boolean }

const Attendance = (props: Props) => {
  const selectedProgram = useProgramStore(state => state.selectedProgram)
  const [filterDate, setFilterDate] = useState({
    dateStartAt: moment(
      selectedProgram?.startAt || SINGLE_BATCH_START_AT
    ).format('YYYY-MM-DD'),
    dateEndAt: moment(selectedProgram?.endAt || SINGLE_BATCH_END_AT).format(
      'YYYY-MM-DD'
    ),
    startAt: selectedProgram?.startAt || SINGLE_BATCH_START_AT,
    endAt: selectedProgram?.endAt || SINGLE_BATCH_END_AT,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<IAbsensiData[]>([]);
  const [eventData, setEventData] = useState<IEventData>({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [resUser, resAttendance, resEvent] = await Promise.all([
        httpRequest.get<BaseResponsePaginationProps<UserProperties>>(
          'users?roles=talent&sort=name:ASC' +
          (props.talentId ? '&userIds=' + props.talentId : '')
        ),
        httpRequest.get<BaseResponsePaginationProps<AttendanceProps>>(
          `attendance?rangeCheckInAtStart=${filterDate.startAt}&rangeCheckInAtEnd=${filterDate.endAt}` +
          (props.talentId ? '&userIds=' + props.talentId : '')
        ),
        httpRequest.get<BaseResponsePaginationProps<EventsProps>>(
          `event?startAt=${filterDate.startAt}&endAt=${filterDate.endAt}`
        ),
      ]);
      const dates = generateDates(filterDate.dateStartAt, filterDate.dateEndAt);

      const data: IAbsensiData[] = []
      let event: IEventData = {};
      if (resEvent?.data?.payload?.results) {
        event = resEvent.data.payload.results.reduce((acc, curr) => {
          if (
            ![
              EEventType.BASIC_TRAINING,
              EEventType.WSCC_TRAINING,
              EEventType.OJT,
              EEventType.DBI_PROJECT,
            ].includes(curr.eventType)
          ) {
            return acc;
          }

          if (curr.dates) {
            for (const dateItem of curr.dates) {
              if (dateItem) {
                const date = moment(dateItem.split(',')[0]).format('YYYY-MM-DD');

                if (!acc[date]) {
                  acc[date] = [];
                }

                acc[date].push(curr);
              }
            }
          } else {
            const date = moment(curr.startAt).format('YYYY-MM-DD');

            if (!acc[date]) {
              acc[date] = [];
            }

            acc[date].push(curr);
          }

          return acc;
        }, {} as IEventData);
      }
      setEventData(event);

      if (resUser?.data?.payload?.results) {
        for (const user of resUser?.data?.payload?.results) {
          let attendance: IAbsensiData['attendance'] = {};

          if (resAttendance?.data?.payload?.results) {
            attendance = resAttendance.data.payload.results.filter(att => att.userId === user.userId).reduce((acc, curr) => {
              const date = moment(curr.checkInAt).format('YYYY-MM-DD');

              acc[date] = curr;

              return acc;
            }, {} as IAbsensiData['attendance']);

            for (const date of dates) {
              if (!attendance[date] && event[date]?.length > 0) {
                if (isPast(date)) {
                  attendance[date] = {
                    userId: user.userId,
                    attendanceId: '',
                    checkInAt: new Date(date),
                    status: AttendanceStatus.ALPHA,
                  };
                } else {
                  attendance[date] = {
                    userId: user.userId,
                    attendanceId: '',
                    checkInAt: new Date(date),
                    status: AttendanceStatus.UPCOMING,
                  };
                }
              } else if (!attendance[date] && !isPast(date)) {
                attendance[date] = {
                  userId: user.userId,
                  attendanceId: '',
                  checkInAt: new Date(date),
                  status: AttendanceStatus.UPCOMING,
                };
              }
            }
          }
          const summary = {
            [AttendanceStatus.PRESENT]: calculateAttendanceStatus(
              attendance,
              AttendanceStatus.PRESENT
            ),
            [AttendanceStatus.LATE]: calculateAttendanceStatus(
              attendance,
              AttendanceStatus.LATE
            ),
            [AttendanceStatus.PERMISSION]: calculateAttendanceStatus(
              attendance,
              AttendanceStatus.PERMISSION
            ),
            [AttendanceStatus.SICK]: calculateAttendanceStatus(
              attendance,
              AttendanceStatus.SICK
            ),
            [AttendanceStatus.ALPHA]: calculateAttendanceStatus(
              attendance,
              AttendanceStatus.ALPHA
            ),
            [AttendanceStatus.UPCOMING]: calculateAttendanceStatus(
              attendance,
              AttendanceStatus.UPCOMING
            ),
          };
          data.push({
            key: user.userId,
            userId: user.userId,
            name: user.name,
            email: user.email,
            attendance,
            summary,
          });
        }

        setTableData(data)
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      getStandardError(err, { showToast: true });
    }
  }

  const tableRef: any = useRef(null);

  const handleDownloadExcel = () => {
    if (tableRef.current) {
      tableRef.current.handleDownloadExcelTemplate();
    }
  };

  return (
    <React.Fragment>
      {!props.hideHeader && (
        <HeaderSection
          icon={<TagOutlined />}
          title="Daily Attendances by Talent"
          subtitle="Manage all daily attendances here"
          rightAction={
            <div style={{ display: 'flex' }}>
              <FilterDate
                startAt={filterDate.startAt}
                endAt={filterDate.endAt}
                onChange={(value) => {
                  setFilterDate({
                    startAt: value.startAt,
                    endAt: value.endAt,
                    dateStartAt: moment(value.startAt).format('YYYY-MM-DD'),
                    dateEndAt: moment(value.endAt).format('YYYY-MM-DD'),
                  });
                }}
                {...(selectedProgram?.programId
                  ? {
                    enableStartAt: selectedProgram.startAt,
                    enableEndAt: selectedProgram.endAt,
                  }
                  : {})}
              />
              <Button type="primary" style={{ marginLeft: '15px' }} onClick={handleDownloadExcel}>
                Download Excel
              </Button>

            </div>
          }
        />
      )}

      <AbsensiTable
        ref={tableRef}
        isLoading={isLoading}
        data={tableData}
        event={eventData}
        dateStartAt={filterDate.dateStartAt}
        dateEndAt={filterDate.dateEndAt}
      />
    </React.Fragment>
  );
};

export default Attendance;

function calculateAttendanceStatus(objAttendance: IAbsensiData['attendance'], status: AttendanceStatus) {
  return Object.keys(objAttendance).filter(date => objAttendance[date]?.status === status).length;
}