import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import React from "react";
import ByHistory from "./Result/ByHistory";
import { BaseResponseProps, HeaderSection } from "@qlibs/react-components";
import { UserOutlined } from "@ant-design/icons";
import { EQuizType, ESubmitType, QuizProperties } from "../../types/quiz.type";
import { useParams } from "react-router-dom";
import { httpRequest } from "../../helpers/api";
import ByTalent from "./Result/ByTalent";
import { renderTextSubmitQuizType } from "./components/RenderSubmitType";
import ByEvent from './Result/ByEvent';
import ByEventDaily from './Result/ByEventDaily';

interface ILocation {
  quizId: string;
}

export default function Result({
  quizId: quizIdFromProps,
  isArchive,
}: {
  quizId?: string;
  isArchive?: boolean;
}) {
  const [quiz, setQuiz] = React.useState<QuizProperties | undefined>(undefined);
  const { quizId: quizIdFromParams } = useParams<
    keyof ILocation
  >() as ILocation;
  const [isLoadingQuiz, setIsLoadingQuiz] = React.useState<boolean>(false);

  const quizId = quizIdFromProps || quizIdFromParams;

  React.useEffect(() => {
    fetchQuiz();
  }, [quizId]);

  const fetchQuiz = async () => {
    try {
      setIsLoadingQuiz(true);
      const res = await httpRequest.get<BaseResponseProps<QuizProperties>>(
        "/quizzes/" + quizId + `?isArchive=${isArchive}`
      );
      if (res && res.data && res.data.payload) {
        setQuiz({
          ...res.data.payload,
        });
      }

      setIsLoadingQuiz(false);
    } catch (error: any) {
      console.log(error);
      setIsLoadingQuiz(false);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "by-talent",
      label: "By Talent",
      children: <ByTalent quiz={quiz} roles="talent" />,
    },
    quiz?.quizType === EQuizType.MICRO_LEARNING_PUBLIC && {
      key: "by-public-user",
      label: "By Public User",
      children: <ByTalent quiz={quiz} roles="customer" />,
    },
    quiz?.quizType === EQuizType.SURVEY &&
    quiz?.submitType === ESubmitType.PER_EVENT
      ? {
          key: 'by-event',
          label: 'By Event',
          children: <ByEvent quiz={quiz} />,
        }
      : ({} as any),
    quiz?.quizType === EQuizType.SURVEY &&
    quiz?.submitType === ESubmitType.PER_EVENT_DAILY
      ? {
          key: 'by-event',
          label: 'By Event ~',
          children: <ByEventDaily quiz={quiz} />,
        }
      : ({} as any),
    {
      key: "by-history",
      label: "Historical",
      children: <ByHistory quiz={quiz} />,
    },
  ].filter((item) => item.label);
  return (
    <>
      <HeaderSection
        icon={<UserOutlined />}
        title={`SUBMISSIONS QUIZ "${quiz?.title}" ${
          quiz?.quizType === EQuizType.SURVEY
            ? `SUBMIT TYPE "${renderTextSubmitQuizType(quiz?.submitType!)}"`
            : ""
        } `}
      />
      <Tabs
        defaultActiveKey="by-talent"
        items={items}
        className="custom-tabs"
      />
    </>
  );
}
