import { ArrowRightOutlined, EditOutlined, EyeOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Modal, Row, Table, Typography } from 'antd';
import React from 'react';
import { useMemo, useState } from 'react';
import usePermission from '../../../hooks/usePermission';
import { ICompetenceItemLevel1, ICompetenceItemLevel2 } from '../types/competence.type';
import AttendanceByTalent from './AttendanceByTalent';
import DailyAchievementResultByTalent from './DailyAchievementResultByTalent';
import DBIQuizResultByEvent from './DBIQuizResultByEvent';
import FormInputGroupedList from './FormInputGroupedList';
import FormInputScore from './FormInputScore';
import OJTQuizResultByEvent from './OJTQuizResultByEvent';
import ScoreReferences from './ScoreReferences';
import WSCCTestResultByEvent from './WSCCTestResultByEvent';

const {Text} = Typography;

type ListEvaluationTableAndFormProps = {
  userId: string;
  competenceCode: string;
  code: string;
  // eventId: string;
  items: ICompetenceItemLevel1[];
  hasChanges: any;

  // onChangesAnything: (competenceCode: string, eventId?: string) => void;
};
export default function ListEvaluationTableAndForm(props: ListEvaluationTableAndFormProps) {
  const listData =
    props.code === 'training'
      ? props.items
      : (props.items || []).length === 1 &&
        (props.items[0].items_level2 || []).length > 0
      ? props.items[0].items_level2
      : props.items || [];
  const {isUserHasPermission} = usePermission();
  const [list, setList] = useState<ICompetenceItemLevel1[]>(
    listData as any[],
  );

  const [shownModal, setShownModal] = useState<{type: 'quiz' | 'daily_achievement' | 'ojt' | 'dbi' | 'attendance__in_class'; eventId?: string; title?: string}>();

  // const hasPredefined = useMemo(
  //   () => (props.items.find((item) => item.type === 'auto_calculate') ? true : false),
  //   [props.items]
  // );
  const hasMinScore = useMemo(
    () => (props.items.find((item) => item.minScore) ? true : false),
    [props.items]
  );
  const hasPredefined = false;

  const hasAction = props.code === 'training' || props.code === 'ojt' || props.code === 'dbi' ? true : false;
  const hasWeight = list.find(item => item.weight) ? true : false;

  const baseColumns: any[] = [
    {
      title: 'Item Name',
      dataIndex: 'title',
      key: 'title',
      editable: !hasPredefined,
      required: true,
      width: 250,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      editable: !hasPredefined,
      width: 350,
      render: (value: any, record: ICompetenceItemLevel1) => {
        if (value?.includes(' | ')) {
          return (
            <ul style={{ margin: 0 }}>
              {value.split(' | ').map((item: string, key: number) => (
                <li key={key}>{item}</li>
              ))}
            </ul>
          );
        } else if (
          record.code === 'training' &&
          record.items_level2 &&
          (record.items_level2 || []).length > 1
        ) {
          const preTest = record.items_level2.find(
            (item) => item.subCode === 'training__pretest_score'
          );
          const postTest = record.items_level2.find(
            (item) => item.subCode === 'training__posttest_score'
          );
          const offlineExercise = record.items_level2.find(
            (item) => item.subCode === 'training__offline_exercise_score'
          );

          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {preTest ? (
                <Text style={{ fontSize: 12, color: 'darkgrey' }}>
                  ({(preTest?.weight || 0) * 100}%) PRETEST :{' '}
                  {preTest?.originalScore || 0}{' '}
                  <ArrowRightOutlined style={{ fontSize: 12 }} />{' '}
                  {preTest?.finalScore}
                  {isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS']) &&
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setShownModal({
                        type: 'quiz',
                        eventId: preTest.additionalData?.event?.eventId,
                        title:
                          preTest.title +
                          ' - ' +
                          preTest.additionalData?.event?.title,
                      });
                    }}
                  >
                    Show Submissions
                  </Button>}
                </Text>
              ) : (
                false
              )}
              {postTest ? (
                <Text style={{ fontSize: 12, color: 'darkgrey' }}>
                  ({(postTest?.weight || 0) * 100}%) POSTTEST :{' '}
                  {postTest?.originalScore || 0}{' '}
                  <ArrowRightOutlined style={{ fontSize: 12 }} />{' '}
                  {postTest?.finalScore}

                  {isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS']) &&
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setShownModal({
                        type: 'quiz',
                        eventId: postTest.additionalData?.event?.eventId,
                        title:
                          postTest.title +
                          ' - ' +
                          postTest.additionalData?.event?.title,
                      });
                    }}
                  >
                    Show Submissions
                  </Button>}
                </Text>
              ) : (
                false
              )}
              {offlineExercise ? (
                <Text style={{ fontSize: 12, color: 'darkgrey' }}>
                  ({(offlineExercise?.weight || 0) * 100}%) OFFLINE EXERCISE :{' '}
                  {offlineExercise?.originalScore || 0}{' '}
                  <ArrowRightOutlined style={{ fontSize: 12 }} />{' '}
                  {offlineExercise?.finalScore}
                </Text>
              ) : (
                false
              )}
            </div>
          );
        } else if (
          record.code === 'ojt' &&
          record.items_level2 &&
          (record.items_level2 || []).length > 1
        ) {
          if (isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS'])) {
            return (
              <Button
                type="link"
                size="small"
                style={{ fontSize: 12 }}
                onClick={() => {
                  setShownModal({
                    type: 'ojt',
                    eventId: record.eventId,
                    title: record.title,
                  });
                }}
              >
                Show OJT Plan & Report
              </Button>
            );
          }
        } else if (
          record.code === 'dbi' &&
          record.items_level2 &&
          (record.items_level2 || []).length > 1
        ) {
          if (isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS'])) {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: 12, color: 'darkgrey' }}>
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setShownModal({
                        type: 'dbi',
                        eventId: record.eventId,
                        title: record.title,
                      });
                    }}
                  >
                    Show Submissions
                  </Button>
                </Text>
              </div>
            );
          }
        } else if (record.code === 'attendance') {
          if (
            (record as ICompetenceItemLevel2).subCode ===
            'attendance__daily_achievement'
          ) {
            return (
              <React.Fragment>
                <Text style={{ color: 'gray' }}>{value}</Text>

                {isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS']) && (
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setShownModal({
                        type: 'daily_achievement',
                      });
                    }}
                  >
                    Show Submissions
                  </Button>
                )}
              </React.Fragment>
            );
          } else if ((record as ICompetenceItemLevel2).subCode === 'attendance__in_class') {
            return (
              <React.Fragment>
                <Text style={{ color: 'gray' }}>{value}</Text>

                {isUserHasPermission(['EVALUATION.VIEW_SUBMISSIONS']) && (
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setShownModal({
                        type: 'attendance__in_class',
                      });
                    }}
                  >
                    Show Attendance
                  </Button>
                )}
              </React.Fragment>
            );
          } else {
            return <Text style={{ color: 'gray' }}>{value}</Text>;
          }
        } else {
          return value;
        }
      },
    },
    hasMinScore
      ? {
          title: 'Min Score',
          dataIndex: 'minScore',
          key: 'minScore',
        }
      : undefined,
    hasWeight ? {
      title: 'Original Score',
      dataIndex: 'originalScore',
      key: 'originalScore',
    } : undefined,
    hasWeight ? {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      render: (value: number) => (value * 100) + '%'
    } : undefined,
    {
      title: 'Final Score',
      dataIndex: 'finalScore',
      key: 'finalScore',
    },
    // {
    //   title: 'Notes',
    //   dataIndex: 'notes',
    //   key: 'notes',
    //   editable: true,
    //   width: 200,
    // },
    hasAction && {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (value: any, record: ICompetenceItemLevel1) => {
        return (
          <React.Fragment>
            {props.code === 'training' &&
            !isUserHasPermission(['EVALUATION.UPDATE_TRAINING_SCORE']) ? (
              <></>
            ) : props.code === 'training' &&
              isUserHasPermission(['EVALUATION.UPDATE_TRAINING_SCORE']) ? (
              <Button
                size="small"
                icon={<EditOutlined style={{ color: 'red', fontSize: 20 }} />}
                onClick={() => {
                  const isAWSGB =
                    record.eventId === 'aws' ||
                    record.eventId === 'general_banking';

                  let title = record.title;
                  let score = record.finalScore;
                  if (
                    !isAWSGB &&
                    record.items_level2 &&
                    (record.items_level2 || []).length > 1
                  ) {
                    const findExercise = record.items_level2.find(
                      (item) =>
                        item.subCode === 'training__offline_exercise_score'
                    );
                    title = findExercise?.title || 'Score';
                    score = findExercise?.originalScore || 0;
                  }

                  // handleDelete(record);
                  Modal.confirm({
                    title: record.title,
                    width: 800,
                    content: (
                      <div>
                        <FormInputScore
                          userId={props.userId}
                          code={props.code}
                          eventId={record.eventId}
                          subCode={
                            isAWSGB
                              ? 'training__single_score'
                              : 'training__offline_exercise_score'
                          }
                          initialValues={{ score }}
                          title={title}
                          label={title}
                        />
                        <ScoreReferences />
                      </div>
                    ),
                    footer: false,
                    closable: true,
                  });
                }}
              >
                Score
              </Button>
            ) : record.items_level2 ? (
              <Row gutter={10}>
                <Col>
                  {isUserHasPermission(['EVALUATION.UPDATE_OJT_DBI_SCORE']) && (
                    <Button
                      size="small"
                      icon={
                        <EditOutlined
                          style={{ color: 'darkorange', fontSize: 20 }}
                        />
                      }
                      onClick={() => {
                        Modal.confirm({
                          title: record.title,
                          content: (
                            <div>
                              <FormInputGroupedList
                                title={record.title}
                                userId={props.userId}
                                code={props.code}
                                eventId={record.eventId || ''}
                                items={record.items_level2 || []}
                              />
                            </div>
                          ),
                          footer: false,
                          closable: true,
                          width: 1000,
                        });
                      }}
                    >
                      Input Score
                    </Button>
                  )}

                  <Button
                    style={{ marginTop: 5 }}
                    size="small"
                    icon={
                      isUserHasPermission([
                        'EVALUATION.UPDATE_OJT_DBI_SCORE',
                      ]) ? (
                        <EditOutlined
                          style={{ color: 'darkorange', fontSize: 20 }}
                        />
                      ) : (
                        <EyeOutlined
                          style={{ color: 'darkorange', fontSize: 20 }}
                        />
                      )
                    }
                    onClick={() => {
                      Modal.confirm({
                        title: record.title,
                        content: (
                          <div>
                            <FormInputGroupedList
                              mode={
                                isUserHasPermission([
                                  'EVALUATION.UPDATE_OJT_DBI_SCORE',
                                ])
                                  ? 'final-notes'
                                  : 'readonly'
                              }
                              title={record.title}
                              userId={props.userId}
                              code={props.code}
                              eventId={record.eventId || ''}
                              items={record.items_level2 || []}
                            />
                          </div>
                        ),
                        footer: false,
                        closable: true,
                        width: 1000,
                      });
                    }}
                  >
                    {isUserHasPermission(['EVALUATION.UPDATE_OJT_DBI_SCORE'])
                      ? 'Input Main Mentor Notes'
                      : 'View Detail'}
                  </Button>
                </Col>
              </Row>
            ) : (
              false
            )}
          </React.Fragment>
        );
      },
    },
  ].filter((item) => item);

  return (
    <div>
      <div className="my-3">
        <Table
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={list}
          columns={baseColumns}
          pagination={false}
          locale={
            props.code === 'training' &&
            isUserHasPermission(['EVALUATION.UPDATE_TRAINING_SCORE'])
              ? {
                  emptyText: (
                    <Empty description="No Related WSCC Training">
                      <Button
                        type="primary"
                        href={`/basic-configurations?key=${props.competenceCode}_WSCC_TRAINING`}
                      >
                        Setup Related Training
                      </Button>
                    </Empty>
                  ),
                }
              : props.code === 'training' &&
                !isUserHasPermission(['EVALUATION.UPDATE_TRAINING_SCORE'])
              ? {
                  emptyText: (
                    <Empty description="No Related WSCC Training">
                      You don't have permission to setup the training. Please
                      contact administrator to setup.
                    </Empty>
                  ),
                }
              : undefined
          }
        />
      </div>

      {shownModal?.eventId && shownModal?.type === 'quiz' ? (
        <WSCCTestResultByEvent
          eventId={shownModal.eventId}
          talentId={props.userId}
          title={shownModal.title || ''}
          onClose={() => {
            setShownModal(undefined);
          }}
          onCancel={() => {
            setShownModal(undefined);
          }}
        />
      ) : (
        false
      )}

      {shownModal?.type === 'daily_achievement' ? (
        <DailyAchievementResultByTalent
          talentId={props.userId}
          onClose={() => {
            setShownModal(undefined);
          }}
          onCancel={() => {
            setShownModal(undefined);
          }}
        />
      ) : (
        false
      )}

      {shownModal?.eventId && shownModal?.type === 'ojt' ? (
        <OJTQuizResultByEvent
          eventId={shownModal.eventId}
          talentId={props.userId}
          title={shownModal.title || ''}
          onClose={() => {
            setShownModal(undefined);
          }}
          onCancel={() => {
            setShownModal(undefined);
          }}
        />
      ) : (
        false
      )}

      {shownModal?.eventId && shownModal?.type === 'dbi' ? (
        <DBIQuizResultByEvent
          eventId={shownModal.eventId}
          talentId={props.userId}
          title={shownModal.title || ''}
          onClose={() => {
            setShownModal(undefined);
          }}
          onCancel={() => {
            setShownModal(undefined);
          }}
        />
      ) : (
        false
      )}

      {shownModal?.type === 'attendance__in_class' ? (
        <AttendanceByTalent
          talentId={props.userId}
          title={shownModal.title || 'Attendance'}
          onClose={() => {
            setShownModal(undefined);
          }}
          onCancel={() => {
            setShownModal(undefined);
          }}
        />
      ) : (
        false
      )}
    </div>
  );
}
