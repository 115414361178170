/* eslint-disable react-hooks/exhaustive-deps */
import { Spin, Card, Button, Divider, Row, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { generateFullname, HeaderSection } from '@qlibs/react-components';
import { useParams } from 'react-router-dom';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { UserProperties } from '../user/types/user.type';
import AllEvaluationPerUser from './components/AllEvaluationPerUser';
import usePromiseAllData from '../../hooks/usePromiseAllData';
import { ICompetence } from './types/competence.type';
import ListMentoring from './components/ListMentoring';
import EvaluationChangeLogs from './components/EvaluationChangeLogs';
import React from 'react';
import { FireOutlined, UserSwitchOutlined } from '@ant-design/icons';
import useProgramStore from '../../zustand/useProgramStore';
import usePermission from '../../hooks/usePermission';

type IPromiseAllData = {
  user: UserProperties;
  competencies: ICompetence[];
}

type Props = {
  mode?: 'input' | 'report';
};
export default function EvaluationDetail(props: Props) {
  const { isUserHasPermission } = usePermission();
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const selectedProgram = useProgramStore((state) => state.selectedProgram);
  
  const params = useParams();
  const [isShowChangeLogModal, setIsShowChangeLogModal] = useState(false);
  const [isShowMentoringModal, setIsShowMentoringModal] = useState(false);
  
  const {
    isLoadingPromiseAllData: isLoading,
    promiseAllData,
    fetchPromiseAllData,
  } = usePromiseAllData<IPromiseAllData>({
    endpoints: [
      {
        endpoint: '/users/' + params.userId,
        returnKey: 'user',
      },
      {
        endpoint: 'evaluation-calculator/' + params.userId,
        returnKey: 'competencies',
      },
    ],
  });

  useEffect(() => {
    fetchPromiseAllData();
  }, []);
  
  const user = promiseAllData.user as UserProperties;
  const competencies = (promiseAllData.competencies as ICompetence[] || []).map(item => ({...item, key: item.competenceCode, label: item.title}));

  useEffect(() => {
    if (!promiseAllData?.user) {
      return;
    }
    const dataUser = promiseAllData.user;
    const fullName =
      dataUser.name ||
      generateFullname({
        firstName: dataUser.firstName,
        middleName: dataUser.middleName,
        lastName: dataUser.lastName,
      });

    let label = `Evaluation ${fullName}`;
    const bcDetails = [
      {
        field: 'userId',
        value: params.userId || '',
        label,
      },
    ];
    setBreadcrumbDetails(bcDetails);
  }, [promiseAllData]);

  return (
    <>
      <HeaderSection
        title={'Evaluation - ' + (user?.name || '...')}
        rightAction={
          <Row>
            <Button
              icon={<UserSwitchOutlined />}
              type="default"
              onClick={() => {
                setIsShowMentoringModal(true);
              }}
              style={{ marginRight: 10 }}
            >
              See Mentoring
            </Button>
            {isUserHasPermission(['EVALUATION.UPDATE']) && (
              <Button
                icon={<FireOutlined />}
                type="default"
                onClick={() => {
                  setIsShowChangeLogModal(true);
                }}
              >
                See Change Logs
              </Button>
            )}
          </Row>
        }
      />

      <Spin spinning={isLoading}>
        <Card>
          {params.userId && competencies.length > 0 ? (
            <AllEvaluationPerUser
              userId={params.userId}
              competencies={competencies}
              mode={props.mode}
            />
          ) : (
            false
          )}
        </Card>

        <Divider />

        {!!params.userId && (
          <React.Fragment>
            <Modal
              open={isShowChangeLogModal}
              onClose={() => {
                setIsShowChangeLogModal(false);
              }}
              onCancel={() => {
                setIsShowChangeLogModal(false);
              }}
              footer={false}
              width={1000}
              closable
            >
              <EvaluationChangeLogs talentId={params.userId} />
            </Modal>

            <Modal
              open={isShowMentoringModal}
              onClose={() => {
                setIsShowMentoringModal(false);
              }}
              onCancel={() => {
                setIsShowMentoringModal(false);
              }}
              footer={false}
              width={1000}
              closable
            >
              <ListMentoring talentId={params.userId} />
            </Modal>
          </React.Fragment>
        )}
      </Spin>
    </>
  );
}
